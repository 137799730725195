import React from "react";
import Layout from "../../components/layout/Layout";
import SEO from "../../components/SEO";
import Header from "../../components/layout/Header.js";
import SiteTitleQuery from "../../components/SiteTitleQuery";
import ProjectPlanningImage from "../../images/inHouseImages/projectPlanningImage.jpg";
// import ProfileCard from "../../components/ProfileCard.js";
// import Max from "../../images/profiles/Namn.jpg";

function projectPlanning() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title="Projektledning"
            keywords={[`projekt`, `projektledning`, `myndighetskrav`]}
          />
          <Header data={data} />

          <div className="container mx-auto flex flex-col md:flex-row items-center my-8 md:my-10 justify-center">
            <div className="sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-3xl rounded overflow-hidden shadow">
              <img
                className="w-full"
                src={ProjectPlanningImage}
                alt="projektledning"
                title="projektledning"
              ></img>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Projektledning</div>
                <p className="text-gray-700 text-base">
                  PSK Syd har vana av att arbeta i branscher som styrs av
                  strikta myndighetskrav, lagar och förordningar inom säkerhet
                  och miljö. Här hjälper vi till med projektledning inom flera
                  områden exempelvis myndighetsadministration och
                  kompetenskrävande konsultarbeten.
                  <br></br>
                  <br></br>
                  Våra projektledares långa erfarenhet och gedigna kunskap gör
                  att vi får ansvar för att driva krävande projekt för våra
                  kunder. Vi tar ansvar för ett projekts planering, genomförande
                  och avslut till rätt kvalitét inom fastställda kostnads- och
                  tidsramar.
                  <br></br>
                  <br></br>
                  Exempel på projekt vi genomfört är:
                  <ul className=" px-6 mb-5 list-disc">
                    <br></br>
                    <li>
                      Rikstäckande inventering av anläggningar inför
                      omprofilering, komplett med fotografering, bygglovsprocess
                      och ekonomisk uppföljning.
                    </li>
                    <li>
                      Fortlöpande utredningar av vattenskyddsområde för att
                      säkerställa förutsättningar vid miljötillsyner.
                    </li>
                    <li>
                      Planering och projektering av laddstolpar för elbilar runt
                      om i Sverige.
                    </li>
                  </ul>
                </p>
              </div>
              {/* <ProfileCard
                profile={Max}
                name="Max Knutas"
                email="mk@psksyd.com"
              /> */}
            </div>
          </div>
        </Layout>
      )}
    />
  );
}

export default projectPlanning;
